import React from "react"
import { PageHeader } from "../common/pageHeaders"
import PageText from "../common/pageText"
import style from "./project.module.css"
import { Link } from "gatsby"
import { PROJECT_ITEMS } from "../../utils/urls"

const Project = () => {
  return (
    <>
      <section>
        <PageHeader>Projects</PageHeader>
        <div className={style.bodyLayout}>
          <PageText>
            Here are recent projects I’m proud of; some were a collaborative
            effort and others were independent. Hope you like them too!
          </PageText>
        </div>
        <div className={style.projectContainer}>
          {PROJECT_ITEMS.map(({ to, label, src }) => {
            return (
              <Link to={to} className={style.projectItem}>
                <img src={src} className={style.projectSvg} alt={label} />
                <div className={style.projectItemCover}>
                  Click to check out: {label}
                </div>
              </Link>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Project
