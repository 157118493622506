import React from "react"
import Layout from "../../components/layout"
import Project from "../../components/Projects"
import { PROJECTS_SEO } from "../../config/siteMetaData"

const ProjectPage = () => {
  return (
    <Layout {...PROJECTS_SEO}>
      <Project />
    </Layout>
  )
}

export default ProjectPage
